// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/login.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  position: relative;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-position: center;
  background-repeat: no-repeat;
}
.auth-container__login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 25px 30px;
  border-radius: 5px;
}
.auth-container__login--title {
  text-align: left;
  padding: 15px 0px;
  font-size: 1.5rem;
}

.otp-resent {
  color: rgb(64, 179, 64);
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/containers/Auth/styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,oEAAA;EACA,2BAAA;EACA,4BAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AACR;AACQ;EACI,gBAAA;EACA,iBAAA;EACA,iBAAA;AACZ;;AAIA;EACI,uBAAA;EACA,iBAAA;AADJ","sourcesContent":[".auth-container{\n    position: relative;\n    height: 100vh;\n    background-image: url('../../assets/images/login.jpg') !important;\n    background-position: center;\n    background-repeat: no-repeat;\n    // background-clip: ;\n    &__login{\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        background-color: white;\n        padding: 25px 30px;\n        border-radius: 5px;\n\n        &--title{\n            text-align: left;\n            padding: 15px 0px;\n            font-size: 1.5rem;\n        }\n    }\n}\n\n.otp-resent{\n    color: rgb(64, 179, 64);\n    font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
