// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/image.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout__body {
  padding: 48px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.layout__body--header {
  background-color: black;
  text-align: center;
  color: white;
}
.layout__body--text {
  padding: 10px;
  margin: 0;
}
@media (max-width: 576px) {
  .layout__body {
    padding: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/Layout/styles.scss","webpack://./src/utils/styles/global.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,oEAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;AAFR;AAIQ;EACI,uBAAA;EACA,kBAAA;EACA,YAAA;AAFZ;AAKQ;EACI,aAAA;EACA,SAAA;AAHZ;ACbQ;EDCJ;IAmBQ,qBAAA;EAHV;AACF","sourcesContent":["@import '../../utils/styles/global.scss';\n\n.layout{\n    &__body{\n        padding: 48px;\n        background-image: url('../../assets/images/image.jpg') !important;\n        background-size: cover;\n        background-position: center;\n        background-attachment: fixed;\n        \n        &--header{\n            background-color: black;\n            text-align: center;\n            color: white;\n        }\n\n        &--text{\n            padding: 10px;\n            margin: 0;\n        }\n\n        @include breakpoint(sm) {\n            padding: 0 !important;\n        }\n    }\n}","@mixin breakpoint($point) {\n    @if $point == sm {\n        @media (max-width: 576px) {\n            @content;\n        }\n    }\n\n    @else if $point == md {\n        @media (max-width: 768px) {\n            @content;\n        }\n    }\n\n    @else if $point == lg {\n        @media (max-width: 992px) {\n            @content;\n        }\n    }\n\n    @else if $point == xl {\n        @media (max-width: 1200px) {\n            @content;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
