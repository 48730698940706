// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .header-first {
  display: flex;
  justify-content: space-between;
  padding: 0.9rem 2rem;
  background: #d4990c;
  color: white;
}
.header .header-first__contact-number {
  display: flex;
  align-items: center;
}
.header .header-first__contact-number--text {
  margin: 0 10px;
}
.header .header-first__user-details {
  display: flex;
  align-items: center;
}
.header .header-first__user-details--text {
  margin: 0 5px;
}
.header .header-first__user-details--text:nth-child(1) {
  margin-right: 20px;
}
.header .header-first__user-details--text:nth-child(2) {
  font-weight: bold;
  cursor: pointer;
}
.header .header-second {
  padding: 0.9rem 2rem;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;AAAR;AAEQ;EACI,aAAA;EACA,mBAAA;AAAZ;AAEY;EACI,cAAA;AAAhB;AAIQ;EACI,aAAA;EACA,mBAAA;AAFZ;AAIY;EAUI,aAAA;AAXhB;AAEgB;EACI,kBAAA;AAApB;AAGgB;EACI,iBAAA;EACA,eAAA;AADpB;AASI;EACI,oBAAA;EACA,uBAAA;AAPR","sourcesContent":[".header{\n    .header-first {\n        display: flex;\n        justify-content: space-between;\n        padding: 0.9rem 2rem;\n        background: #d4990c;\n        color: white;\n\n        &__contact-number {\n            display: flex;\n            align-items: center;\n\n            &--text {\n                margin: 0 10px;\n            }\n        }\n\n        &__user-details {\n            display: flex;\n            align-items: center;\n\n            &--text {\n                &:nth-child(1) {\n                    margin-right: 20px;\n                }\n\n                &:nth-child(2) {\n                    font-weight: bold;\n                    cursor: pointer;\n                }\n\n                margin: 0 5px;\n            }\n        }\n    }\n\n    .header-second{\n        padding: 0.9rem 2rem;\n        background-color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
